import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../../components/layout'
import Accessorie from '../../components/products/Accessories'
import Breadcrumbs from '../../components/sections/Breadcrumbs';

export default ({data}) => {
    const product = data.product
    return (
      <Layout>
        <Breadcrumbs />
        <Accessorie product={product}/>
      </Layout>
    )
}


export const query = graphql`
query($slug: String!) {
    product: wpProduct(slug: {eq: $slug}) {
        title
        accessories {
            accessories {
                description
                fieldGroupName
                price
                sizesspecifications
                colours {
                    colour
                }
                images {
                    altText
                    sourceUrl
                }
            }
        }
        relatedProducts {
            product4 {
              ... on WpProduct {
                id
                title
                slug
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        fluid(fit: CONTAIN, maxWidth: 300, quality: 50) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
            product3 {
              ... on WpProduct {
                id
                title
                slug
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        fluid(fit: CONTAIN, maxWidth: 300, quality: 50) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
            product2 {
              ... on WpProduct {
                id
                title
                slug
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        fluid(fit: CONTAIN, maxWidth: 300, quality: 50) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
            product1 {
              ... on WpProduct {
                id
                title
                slug
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        fluid(fit: CONTAIN, maxWidth: 300, quality: 50) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
            showRelatedProducts
          }
    }
}
`