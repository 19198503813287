import React from 'react'

class Checkboxes extends React.Component {
    toggle(id) {
        this.props.toggle(id)
    }
    checkColoursExists() {
       if (this.props.colours) {
           if (this.props.colours[0].colourName) { // Check the colour has a property with the name 'colour_name'
            return (
                <div>
                     <span className="text-lg md:text-xl mb-4 block">{this.props.title}</span>
                     {this.props.colours.map((option, i) => (
                         <div className="w-full border-solid border border-gray-300 hover:border-primary rounded flex items-center mb-4 px-5 py-4" key={i}>
                             <input defaultChecked={this.props.checkbox === i ? true : false } name="colour" onClick={e=>this.toggle(e.target.id)} id={i} type="radio" /> 
                             <p className="checkChange mb-0 pl-4"><b>Colour:</b> {option.colourName}</p>
                         </div>
                     ))}
                 </div>
            )
           } else { // else its a accesories so get the correct data for the colour
            return (
                <div>
                     <span className="text-lg md:text-xl mb-4 block">{this.props.title}</span>
                     {this.props.colours.map((option, i) => (
                         <div className="w-full border-solid border border-gray-300 hover:border-primary rounded flex items-center mb-4 px-5 py-4" key={i}>
                             <input defaultChecked={this.props.checkbox === i ? true : false } name="colour" onClick={e=>this.toggle(e.target.id)} id={i} type="radio" /> 
                             <p className="checkChange mb-0 pl-4"><b>Colour:</b> {option.colour}</p>
                         </div>
                     ))}
                 </div>
            )
           }
       } else {
           return (
               <div></div>
           )
       }
   }
    render() {
        return (
            this.checkColoursExists()
        )
    }
}

export default Checkboxes
