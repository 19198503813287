import React from 'react'

import TabbedInfo from './utilites/tab'
import Checkboxes from './utilites/checkbox'
import ImageGallery from './utilites/product-images'
import MainInfo from './utilites/mainspecs'
import ProductItem from './ProductListingItem'
import Cta from '../sections/Cta'
import Emailer from '../sections/Emailer'


class Accessorie extends React.Component  {
    constructor(props) {
        super(props)
        const productOverwrides = props.overwrites === null ? null : props.overwrites
        this.state = { 
            productOverwrides: null,
            title: props.product.title,
            mainImage: {
                url: props.product.accessories.accessories.images[0].sourceUrl,
                alt: props.product.accessories.accessories.images[0].altText
            },
            images: props.product.accessories.accessories.images,
            colours: props.product.accessories.accessories.colours,
            price: null,
            desc: props.product.accessories.accessories.description,
            spec: props.product.accessories.accessories.sizesspecifications,
            checkbox: 0,
            related: [
                props.product.relatedProducts.product1,
                props.product.relatedProducts.product2,
                props.product.relatedProducts.product3,
                props.product.relatedProducts.product4,
            ],
            showRelated: props.product.relatedProducts.showRelatedProducts
        }
    }
    
    updateImage = (id) => {
        this.setState({
            mainImage: {
                url: this.state.images[id].sourceUrl,
                alt: this.state.images[id].altText,
            },
        })
    }
    toggleOptions = (id) => {
        this.setState({
            checkbox: id,
        })
    }

    relatedEmpty() {
        let empty = this.state.related.filter(item => item )
        return empty.length !== 0 ? true : false
    }

    render() {

        return (
            <div className="container mx-auto">
                <div className="mb-16 md:mb-24">
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 md:pr-12 lg:pr-24">
                            <ImageGallery 
                                toggle={this.updateImage}
                                thumbnails={this.state.images}
                                mainImageUrl={this.state.mainImage.url}
                                mainImageAlt={this.state.mainImage.alt} 
                            />                        
                        </div>
                        <div className="w-full md:w-1/2 md:pl-8">
                            {this.state.title && <h1 className="text-4xl leading-normal">{this.state.title}</h1>}
                            <MainInfo price={this.state.price} dimensions={this.state.dimensions}/>
                            <Checkboxes title="Avaliable Options:" toggle={this.toggleOptions} colours={this.state.colours} checkbox={this.state.checkbox}/>
                            <div className="product__info">
                                <TabbedInfo desc={this.state.desc} info={this.state.spec} />
                            </div>
                        </div>
                    </div>
                </div> 
                {(this.relatedEmpty() && this.state.showRelated) && <div className="mb-10 md:mb-20">
                    <h3 className="px-4 text-3xl mb-8 text-center">Related Products</h3>
                    <div className="flex flex-wrap  mb-8 md:mb-16">
                        {this.state.related.map((product, i) =>  (
                             product && <ProductItem key={i} product={product} />
                        ))}
                    </div>
                </div>}
                <Cta />
                <Emailer />
            </div>
        )
    }
}

export default Accessorie